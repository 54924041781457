import { useTranslate } from '../i18n/hooks/use-translate';
import { GetStarted } from './GetStarted';

export const Hero = ({ heading, subHeading, explanation }) => {
  const { i18n } = useTranslate();
  return (
    <>
      <div>
        <h2 className="text-center text-white text-4xl lg:text-5xl mb-4">
          {i18n(heading)}
        </h2>
        <p className="text-center md:text-xl text-neutral_300">
          {i18n(subHeading)}
        </p>
        <div className="mb-20">
          <GetStarted />
        </div>
      </div>


      <h3 className="text-center text-2xl lg:text-3xl text-white mb-16">
        {i18n(explanation)}
      </h3>
    </>
  );
};
