import { useTranslate } from '../i18n/hooks/use-translate';

export const Screenshot = ({ right, image, title, description }) => {
  const { i18n } = useTranslate();
  if (!right) {
    return (
      <div
        className="flex flex-col lg:flex-row gap-12 mb-16"
        data-id="step-one"
      >
        <div className="lg:basis-[640px] shrink-0 grow-0">
          <img src={image} alt={i18n(title)} />
        </div>
        <div className="order-first lg:order-last lg:pt-16">
          <h4 className="text-xl lg:text-2xl text-white mb-4">
            {i18n(title)}
          </h4>
          <p className="text-neutral_300">{i18n(description)}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col lg:flex-row gap-12 mb-24" data-id="step-two">
      <div className="lg:pt-16">
        <h4 className="text-xl lg:text-2xl text-white mb-4">{i18n(title)}</h4>
        <p className="text-neutral_300">{i18n(description)}</p>
      </div>
      <div className="lg:basis-[640px] shrink-0 grow-0">
        <img src={image} alt={i18n(title)} />
      </div>
    </div>
  );
};
