import { Hero } from './components/Hero';
import { Screenshot } from './components/Screenshot';
import { Features } from './components/Features';

export const NerdsPage = () => (
  <>
    <Hero
      heading="nerdsHeading"
      subHeading="nerdsSubHeading"
      explanation="nerdsSetup"
    />
    <Screenshot
      image="/app-images/home-creator-screenshot-1.png"
      title="nerdsAdd"
      description="nerdsAddDescription"
    />
    <Screenshot
      image="/app-images/home-creator-screenshot-2.png"
      title="nerdsGroup"
      description="nerdsGroupDescription"
      right
    />
    <Screenshot
      image="/app-images/timeline.png"
      title="nerdsTimeline"
      description="nerdsTimelineDescription"
    />

    <Features
      features={[
        {
          image: '/icons/icon-eye.svg',
          title: 'nerdsAccess',
          description: 'nerdsAccessDescription',
        },
        {
          image: '/icons/icon-users.svg',
          title: 'nerdsAudience',
          description: 'nerdsAudienceDescription',
        },
      ]}
    />
    <Screenshot
      image="/app-images/source-options.png"
      title="nerdsSourceOptions"
      description="nerdsSourceOptionsDescription"
    />

  </>
);
