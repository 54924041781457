import { Hero } from './components/Hero';
import { Screenshot } from './components/Screenshot';
import { Features } from './components/Features';

export const OptimizersPage = () => (
  <>
    <Hero
      heading="optimizersHeading"
      subHeading="optimizersSubHeading"
      explanation="optimizersSetup"
    />
    <Screenshot
      image="/app-images/home-creator-screenshot-1.png"
      title="optimizersAdd"
      description="optimizersAddDescription"
    />
    <Screenshot
      image="/app-images/timeline.png"
      title="optimizersTimeline"
      description="optimizersTimelineDescription"
      right
    />

    <Features
      features={[
        {
          image: '/icons/icon-eye.svg',
          title: 'optimizersAccess',
          description: 'optimizersAccessDescription',
        },
        {
          image: '/icons/icon-users.svg',
          title: 'optimizersAudience',
          description: 'optimizersAudienceDescription',
        },
      ]}
    />
    <Screenshot
      image="/app-images/source-options.png"
      title="optimizersSourceOptions"
      description="optimizersSourceOptionsDescription"
    />

  </>
);
