import { Route, Routes, useLocation } from 'react-router-dom';
import { NotFound } from './NotFound';
import { HomePage } from './HomePage';
import { CreatorsPage } from './CreatorsPage';
import { NerdsPage } from './NerdsPage';
import { OptimizersPage } from './OptimizersPage';
import { PricingPage } from './PricingPage';
import { useEffect } from 'react';
import {ReleaseNotesPage} from "./ReleaseNotesPage";

export const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/creators" element={<CreatorsPage />} />
      <Route path="/nerds" element={<NerdsPage />} />
      <Route path="/optimizers" element={<OptimizersPage />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/release-notes" element={<ReleaseNotesPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
