import { useTranslate } from './i18n/hooks/use-translate';

export const ReleaseNotesPage = () => {
  const { i18n } = useTranslate();

  return (
    <div className="flex flex-col items-center">
      <div>
        <h2 className="text-center text-white text-2xl mb-4">
          {i18n('releaseNotesHeader')}
        </h2>
      </div>
      <div className="w-full mt-10 space-y-4">
        {i18n('releaseNotes2')}
      </div>
      <div className="w-full mt-10 space-y-4">
        {i18n('releaseNotes1')}
      </div>
      <div className="w-full mt-10 space-y-4 mb-20">
        {i18n('releaseNotesFeatureIdeas')}
      </div>
    </div>
  );
};
