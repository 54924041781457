import { useTranslate } from '../i18n/hooks/use-translate';

export const Features = ({ features }) => {
  const { i18n } = useTranslate();
  return (
    <div
      className="flex flex-col md:flex-row gap-12 mb-20"
      data-id="feature-list"
    >
      {features.map(({ image, title, description }) => (
        <div className="flex gap-6 basis-0 grow shrink" data-id="feature-one" key={title}>
          <div className="basis-[32px] shrink-0 grow-0">
            <img className="" src={image} alt={i18n(title)} />
          </div>
          <div className="">
            <h5 className="text-xl text-white mb-2">{i18n(title)}</h5>
            <p className="text-neutral_300">{i18n(description)}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
