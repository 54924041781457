import { ENGLISH, PORTUGUESE } from './i18n/languages';

export const TERMS = {
  pageTitle: {
    [ENGLISH]: 'Lemeno: Just what matters. No distractions.',
    [PORTUGUESE]: 'Lemeno: Apenas o que importa. Sem distrações.',
  },
  logIn: {
    [ENGLISH]: 'Log in',
    [PORTUGUESE]: 'Login',
  },
  registerNow: {
    [ENGLISH]: 'Register now',
    [PORTUGUESE]: 'Cadastre-se agora',
  },
  keepYourSocialZero: {
    [ENGLISH]: 'Just what matters. No distractions.',
    [PORTUGUESE]: 'Apenas o que importa. Sem distrações.',
  },
  readyToGetStarted: {
    [ENGLISH]: 'Ready to get started?',
    [PORTUGUESE]: 'Pronto pra começar?',
  },
  takeYourSocialInboxToZero: {
    [ENGLISH]: 'Keep content organized in 2 easy steps',
    [PORTUGUESE]: 'Mantenha o conteúdo organizado em 2 passos',
  },
  lemenoDescription: {
    [ENGLISH]:
      'Read only posts that matter to you. Free of Ads and Algorithms.',
    [PORTUGUESE]:
      'Leia apenas os posts que importam para você. Sem propagandas e algoritmos.',
  },
  getStarted: {
    [ENGLISH]: 'Register for free',
    [PORTUGUESE]: 'Pronto para começar?',
  },
  allRights: {
    [ENGLISH]: '© 2023 Lemeno. All rights reserved.',
    [PORTUGUESE]: '© 2023 Lemeno. Todos os direitos reservados.',
  },
  by: {
    [ENGLISH]: 'by ',
    [PORTUGUESE]: 'desenvolvido pela ',
  },
  sources: {
    [ENGLISH]: '1. Select your sources',
    [PORTUGUESE]: '1. Selecione suas fontes',
  },
  sourcesDescription: {
    [ENGLISH]:
      'Add any Instagram, Twitter or YouTube account to your list with one click. Automatically keep track of all posts.',
    [PORTUGUESE]:
      'Adicione qualquer conta de Instagram, Twitter ou canal de Youtube na sua lista. Automaticamente rastreie as postagens.',
  },
  read: {
    [ENGLISH]: '2. Read content that matters to you',
    [PORTUGUESE]: '2. Leia conteúdos que importam para você',
  },
  readDescription: {
    [ENGLISH]:
      'All posts are presented in a simple timeline. Read them in an environment without distractions, ads or algorithms that prioritize content.',
    [PORTUGUESE]:
      'Todas as postagens em uma única linha do tempo. Leia em um ambiente sem distrações, propagandas ou algoritmos que priorizam conteúdos.',
  },
  archive: {
    [ENGLISH]: 'Archive posts',
    [PORTUGUESE]: 'Arquive postagens',
  },
  archiveDescription: {
    [ENGLISH]:
      'Archive what you have already read. Easily continue from where you left of.',
    [PORTUGUESE]: 'Arquive o que você já leu. Continue de onde você parou.',
  },
  groupSources: {
    [ENGLISH]: 'Group sources',
    [PORTUGUESE]: 'Agrupe fontes',
  },
  groupSourcesDescription: {
    [ENGLISH]:
      'Group sources you follow into a single view for better organization and focus.',
    [PORTUGUESE]:
      'Agrupe fontes que você segue em uma única view para uma melhor organização.',
  },
  readLater: {
    [ENGLISH]: 'Read later',
    [PORTUGUESE]: 'Ler depois',
  },
  readLaterDescription: {
    [ENGLISH]: `Set aside posts you don't have time to read right now.`,
    [PORTUGUESE]: 'Deixe para depois o que você não tem tempo para ler agora.',
  },
  creatorsHeading: {
    [ENGLISH]: `A safe way to grow your audience`,
    [PORTUGUESE]: `Uma forma segura de crescer sua audiência`,
  },
  creatorsSubHeading: {
    [ENGLISH]: `Make sure your followers get all your social media posts. Always.`,
    [PORTUGUESE]: `Garanta que seus seguidores vão receber todos seus posts nas mídias sociais. Sempre.`,
  },
  creatorsLongMessage: {
    [ENGLISH]: `What will happen if Instagram, Twitter or YouTube suspend your account? With Lemeno you always have your audience contact info.`,
    [PORTUGUESE]: `O que vai acontecer se o Instagram, Twitter ou YouTube suspenderem a sua conta? Com Lemeno você sempre tem o contato direto de seus seguidores.`,
  },
  creatorsSetup: {
    [ENGLISH]: `Setup your account and share content with your audience in 3 easy steps:`,
    [PORTUGUESE]: `Configure sua conta e compartilhe seu conteúdo com seus seguidores em 3 passos`,
  },
  creatorsAdd: {
    [ENGLISH]: `1. Add your social media accounts`,
    [PORTUGUESE]: `1. Adicione as contas das suas redes sociais`,
  },
  creatorsAddDescription: {
    [ENGLISH]: `Add your Instagram, Twitter and/or YouTube accounts to Lemeno with one click.`,
    [PORTUGUESE]: `Adicione suas contas no Instagram, Twitter e/ou YouTube no Lemeno com um clique.`,
  },
  creatorsGroup: {
    [ENGLISH]: `2. Group them together`,
    [PORTUGUESE]: `2. Agrupe as contas com views`,
  },
  creatorsGroupDescription: {
    [ENGLISH]: `Create a view with with one or more of your accounts to group them together.`,
    [PORTUGUESE]: `Crie uma view com uma ou mais contas para agrupá-las em um lugar só`,
  },
  creatorsShare: {
    [ENGLISH]: `3. Share the link with your audience`,
    [PORTUGUESE]: `3. Compartilhe o link com os seus seguidores`,
  },
  creatorsShareDescription: {
    [ENGLISH]: `By accessing the link, anyone can view all your posts in Lemeno without distractions, adds or algorithms that prioritize content.`,
    [PORTUGUESE]: `Ao acessar o link, seus seguidores poderão ver todos os seus posts no Lemeno sem distrações, propagandas ou algoritmos que priorizam conteúdos.`,
  },
  creatorsAccess: {
    [ENGLISH]: `Access to ALL your posts`,
    [PORTUGUESE]: `Acesso à TODOS os seus posts`,
  },
  creatorsAccessDescription: {
    [ENGLISH]: `Provide your audience instant access to all your social media posts.`,
    [PORTUGUESE]: `Forneça aos seus seguidores acesso imediato a todos os posts nas suas redes sociais.`,
  },
  creatorsAds: {
    [ENGLISH]: `Your ads get more hits`,
    [PORTUGUESE]: `Suas publis em outra dimensão`,
  },
  creatorsAdsDescription: {
    [ENGLISH]: `Target your ads with direct email instead of relying on big tech filters.`,
    [PORTUGUESE]: `Envie suas publis por e-mail ao invés de confiar nos filtros randômicos das big-techs.`,
  },
  creatorsEmail: {
    [ENGLISH]: `E-mail alerts`,
    [PORTUGUESE]: `Alertas por e-mail`,
  },
  creatorsEmailDescription: {
    [ENGLISH]: `Your followers will be notified by email when you post new content.`,
    [PORTUGUESE]: `Seus seguidores serão notificados por e-mail quando você postar um novo conteúdo.`,
  },
  creatorsAudience: {
    [ENGLISH]: `Audience contact info`,
    [PORTUGUESE]: `Contato dos seguidores`,
  },
  creatorsAudienceDescription: {
    [ENGLISH]: `Ownership of your followers contact info. Reach them directly. Export it any time.`,
    [PORTUGUESE]: `Seja dono das informações de contato de seus seguidores. Fale com eles diretamente. Exporte a qualquer momente.`,
  },
  nerdsHeading: {
    [ENGLISH]: `Follow Instagram, Twitter and YouTube like a real nerd`,
    [PORTUGUESE]: `Siga Instagram, Twitter e YouTube como um verdadeiro nerd`,
  },
  nerdsSubHeading: {
    [ENGLISH]: `Use regex and automatic rules to ignore stuff you don't care about`,
    [PORTUGUESE]: `Use regex e regras automáticas para ignorar coisas que não te importam`,
  },
  nerdsSetup: {
    [ENGLISH]: `Setup your account, create smart views to group similar content in 2 easy steps:`,
    [PORTUGUESE]: `Configure sua conta, crie views inteligentes em 2 passos`,
  },
  nerdsAdd: {
    [ENGLISH]: `1. Add sources you want to follow`,
    [PORTUGUESE]: `1. Adicione contas que você quer seguir`,
  },
  nerdsAddDescription: {
    [ENGLISH]: `Add your Instagram, Twitter and/or YouTube accounts to Lemeno with one click.`,
    [PORTUGUESE]: `Adicione suas contas no Instagram, Twitter e/ou YouTube no Lemeno com um clique.`,
  },
  nerdsGroup: {
    [ENGLISH]: `2. Group them together`,
    [PORTUGUESE]: `2. Agrupe as contas com views`,
  },
  nerdsGroupDescription: {
    [ENGLISH]: `Create a view with with one or more of your accounts to group them together. Lemeno will send an email every day with content count by view.`,
    [PORTUGUESE]: `Crie uma view com uma ou mais contas para agrupá-las em um lugar só. Lemeno vai te avisar diariamente por email quantos conteúdos estão em cada view.`,
  },
  nerdsTimeline: {
    [ENGLISH]: `3. Parse content`,
    [PORTUGUESE]: `3. Leia os conteúdos`,
  },
  nerdsTimelineDescription: {
    [ENGLISH]: `Ready! Now you enjoy your own curated content.`,
    [PORTUGUESE]: `Pronto! Agora é só aproveitar a sua própria seleção de conteúdo.`,
  },
  nerdsAccess: {
    [ENGLISH]: `Auto archive replies and RTs`,
    [PORTUGUESE]: `Arquive respostas e RTs`,
  },
  nerdsAccessDescription: {
    [ENGLISH]: `For each source you can customize what you want to archive automatically.`,
    [PORTUGUESE]: `Pra cada fonte você pode escolher o que arquivar automaticamente.`,
  },
  nerdsAudience: {
    [ENGLISH]: `Filter based on a Regex`,
    [PORTUGUESE]: `Filtre baseado em uma Regex`,
  },
  nerdsAudienceDescription: {
    [ENGLISH]: `Yes, Lemeno supports regex to auto archive as well.`,
    [PORTUGUESE]: `Sim, o Lemeno suporta regex para arquivar automaticamente.`,
  },
  nerdsSourceOptions: {
    [ENGLISH]: `Advanced options by source`,
    [PORTUGUESE]: `Opções avançadas por fonte`,
  },
  nerdsSourceOptionsDescription: {
    [ENGLISH]: `Customize your options for each source to save even more time. For example, auto archive replies from Elon Musk.`,
    [PORTUGUESE]: `Configure suas opções para cada fonte para economizar ainda mais tempo. Por exemplo, arquive automaticamente as respostas do Elon Musk.`,
  },
  optimizersHeading: {
    [ENGLISH]: `Optimize your usage of Instagram, Twitter or YouTube smartly`,
    [PORTUGUESE]: `Otimize seu uso Instagram, Twitter or YouTube de uma forma inteligente`,
  },
  optimizersSubHeading: {
    [ENGLISH]: `Focus on key accounts and channels that you really want to get all the publications`,
    [PORTUGUESE]: `Foque em contas e canais que você realmente quer ver todas as publicações`,
  },
  optimizersSetup: {
    [ENGLISH]: `Add Instagram, Twitter Accounts and YouTube Channels easily and done!`,
    [PORTUGUESE]: `Adicione Contas do Instagram, Twitter e Canais do YouTube e pronto!`,
  },
  optimizersAdd: {
    [ENGLISH]: `1. Add sources you really want to follow`,
    [PORTUGUESE]: `1. Adicione contas que você realmente quer seguir`,
  },
  optimizersAddDescription: {
    [ENGLISH]: `Add your Instagram, Twitter and/or YouTube accounts to Lemeno with one click, only the ones that you really want to follow everything.`,
    [PORTUGUESE]: `Adicione suas contas no Instagram, Twitter e/ou YouTube no Lemeno com um clique, apenas aquelas que você realmente quer seguir tudo.`,
  },
  optimizersTimeline: {
    [ENGLISH]: `2. Parse content`,
    [PORTUGUESE]: `2. Leia os conteúdos`,
  },
  optimizersTimelineDescription: {
    [ENGLISH]: `Ready! Now you enjoy your own curated content with full control.`,
    [PORTUGUESE]: `Pronto! Agora é só aproveitar a sua própria seleção de conteúdo com controle total.`,
  },
  optimizersAccess: {
    [ENGLISH]: `Auto archive replies and RTs`,
    [PORTUGUESE]: `Arquive respostas e RTs`,
  },
  optimizersAccessDescription: {
    [ENGLISH]: `For each source you can customize what you want to archive automatically, so you can optimize your time even more.`,
    [PORTUGUESE]: `Pra cada fonte você pode escolher o que arquivar automaticamente. Assim você otimiza ainda mais o seu tempo.`,
  },
  optimizersAudience: {
    [ENGLISH]: `Important accounts`,
    [PORTUGUESE]: `Contas importantes`,
  },
  optimizersAudienceDescription: {
    [ENGLISH]: `Only add important accounts for you in Lemeno, don't waste your time.`,
    [PORTUGUESE]: `Adicione apenas contas super importantes para você no Lemeno, não perca tempo.`,
  },
  optimizersSourceOptions: {
    [ENGLISH]: `Advanced options by source`,
    [PORTUGUESE]: `Opções avançadas por fonte`,
  },
  optimizersSourceOptionsDescription: {
    [ENGLISH]: `Customize your options for each source to save even more time. For example, auto archive replies from Elon Musk.`,
    [PORTUGUESE]: `Configure suas opções para cada fonte para economizar ainda mais tempo. Por exemplo, arquive automaticamente as respostas do Elon Musk.`,
  },
  areYouANerd: {
    [ENGLISH]: 'Are you a Nerd?',
    [PORTUGUESE]: 'Você é um Nerd?',
  },
  forNerds: {
    [ENGLISH]: 'for Nerds',
    [PORTUGUESE]: 'para Nerds',
  },
  areYouACreator: {
    [ENGLISH]: 'Are you a Creator?',
    [PORTUGUESE]: 'Você é um Criador de Conteúdo?',
  },
  forCreators: {
    [ENGLISH]: 'for Creators',
    [PORTUGUESE]: 'para Criadores de Conteúdo',
  },
  areYouAnOptimizer: {
    [ENGLISH]: 'Optimize your time!',
    [PORTUGUESE]: 'Otimize seu tempo!',
  },
  forOptimizers: {
    [ENGLISH]: 'for Optimizers',
    [PORTUGUESE]: 'para Otimizadores',
  },
  pricing: {
    [ENGLISH]: 'Pricing',
    [PORTUGUESE]: 'Preços',
  },
  contact: {
    [ENGLISH]: 'Contact',
    [PORTUGUESE]: 'Contato',
  },
  pricingHeader: {
    [ENGLISH]: 'How valuable are we to you?',
    [PORTUGUESE]: 'Quão valioso somos para você?',
  },
  pricingSubheader: {
    [ENGLISH]:
      'Our mission is to provide value to you and then you decide how much to pay us.',
    [PORTUGUESE]:
      'Nossa missão é gerar valor para você e aí você decide quanto pagar pra gente',
  },
  pricingText: {
    [ENGLISH]: (
      <>
        <h5 className="text-2xl text-white mb-2">How it works?</h5>
        <p className="text-white font-light">
          Do you know how Free Walking Tours work?
        </p>
        <p className="text-white font-light">
          Then it's easy, Lemeno works in the same way.
        </p>
        <p className="text-white font-light">
          You don't? Ok, I'll explain to you: You don't pay anything upfront and
          then, in the end, you decide how much value was generated during the
          tour and you pay the guide how much you think is fair for the service
          provided.
        </p>
        <p className="text-white font-light">
          Lemeno works in the same way, it's free but you can pay us monthly if
          we provide a good service to you.
        </p>
        <p className="text-white font-light">
          It's a free tool that you can pay monthly if you want to keep us alive
          for a long time.
        </p>
      </>
    ),
    [PORTUGUESE]: (
      <>
        <h5 className="text-2xl text-white mb-2">Como funciona?</h5>
        <p className="text-white font-light">
          Você sabe como funcionam os Tours Turísticos Gratuitos?
        </p>
        <p className="text-white font-light">
          Então é fácil, a gente funciona da mesma forma.
        </p>
        <p className="text-white font-light">
          Não sabe? Blz, vou te explicar: Você não paga nada antes do Tour
          começar e aí no fim você paga quanto você achou que o serviço do guia
          valeu.
        </p>
        <p className="text-white font-light">
          O Lemeno funciona da mesma forma, ele é de graça mas você pode pagar a
          gente mensalmente se a gente estiver fornecendo um bom serviço.
        </p>
        <p className="text-white font-light">
          É uma ferramenta gratuita que você pode pagar mensalmente se quiser
          manter a gente funcionando por muito tempo.
        </p>
      </>
    ),
  },
  paymentLinkText: {
    [ENGLISH]: (
      <>
        <p className="text-white font-light">--</p>
        <p className="text-gray-400 font-light">
          FYI we use Stripe so when you click in the button below you are going
          to be redirect to there. You can pay using your Card.
        </p>
        <p className="text-gray-400 font-light">
          If you want to pay more than 1 dollar a month you can change the
          quantity in the checkout page. For example, if you select the quantity
          of 3 you will pay 3 dollars a month.
        </p>
      </>
    ),
    [PORTUGUESE]: (
      <>
        <p className="text-white font-light">--</p>
        <p className="text-gray-400 font-light">
          A gente usa um sistema de pagamentos chamado Stripe então quando você
          clicar no botão abaixo você será redirecionado para lá. Você pode pagar por cartão ou boleto.
        </p>
        <p className="text-gray-400 font-light">
          Se quiser pagar mais de R$ 5,00 por mês você pode mudar a quantidade
          na hora de fazer sua compra. Por exemplo, se você escolher 3 como
          quantidade você vai pagar R$ 15,00 por mês.
        </p>
      </>
    ),
  },
  paidBenefitsText: {
    [ENGLISH]: (
      <>
        <h5 className="text-2xl text-white mb-2">Benefits for Paid Accounts</h5>
        <p className="text-white font-light">
          - No email notifications limit{' '}
          <span className="text-gray-400 text-xs">
            free accounts get up to 50 notifications per month
          </span>
        </p>
        <p className="text-white font-light">
          Do you have ideas for benefits? Let us know at lemeno@lemeno.io!
        </p>
      </>
    ),
    [PORTUGUESE]: (
      <>
        <h5 className="text-2xl text-white mb-2">
          Benefícios para Contas Pagas
        </h5>
        <p className="text-white font-light">
          - Sem limite de notificações por email{' '}
          <span className="text-gray-400 text-xs">
            contas gratuitas recebem no máximo 50 notificações por mês
          </span>
        </p>
        <p className="text-white font-light">
          Você tem ideias para benefícios? Nos envie por email lemeno@lemeno.io!
        </p>
      </>
    ),
  },
  subscribeNow: {
    [ENGLISH]: 'Subscribe now',
    [PORTUGUESE]: 'Assine agora',
  },
  releaseNotesHeader: {
    [ENGLISH]: 'Release Notes',
    [PORTUGUESE]: 'Atualizações',
  },
  releaseNotesFeatureIdeas: {
    [ENGLISH]: (
      <>
        <p className="text-white text-center font-light">Have ideas?</p>
        <p className="text-gray-400 font-light">
          Let us know how we can increase the value of Lemeno to you:
          lemeno@lemeno.io.
        </p>
      </>
    ),
    [PORTUGUESE]: (
      <>
        <p className="text-white text-center font-light">Tem ideias?</p>
        <p className="text-gray-400 font-light">
          Nos conte como podemos aumentar o valor do Lemeno para você:
          lemeno@lemeno.io.
        </p>
      </>
    ),
  },
  releaseNotes1: {
    [ENGLISH]: (
      <>
        <p className="text-white font-light">March 13th, 2023</p>
        <p className="text-gray-400 font-light">
          - Fixes sync when Twitter account screen name is changed
        </p>
        <p className="text-gray-400 font-light">
          - Let you know when a Twitter or Instagram account is no longer public
        </p>
      </>
    ),
    [PORTUGUESE]: (
      <>
        <p className="text-white font-light">13/03/2023</p>
        <p className="text-gray-400 font-light">
          - Corrige problema de sincronização do Twitter quando o nome da conta
          é alterado.
        </p>
        <p className="text-gray-400 font-light">
          - Avisa quando uma conta do Twitter ou Instagram não é mais pública.
        </p>
      </>
    ),
  },
  releaseNotes2: {
    [ENGLISH]: (
      <>
        <p className="text-white font-light">April 2nd, 2023</p>
        <p className="text-gray-400 font-light">
          - Adds support to import Feedly opml file
        </p>
        <p className="text-gray-400 font-light">
          - Adds Option to edit Source after adding it including changing views
        </p>
      </>
    ),
    [PORTUGUESE]: (
      <>
        <p className="text-white font-light">02/04/2023</p>
        <p className="text-gray-400 font-light">
          - Adiciona suporte para importação de arquivos opml do Feedly
        </p>
        <p className="text-gray-400 font-light">
          - Adiciona opção de editar fonte depois de adicioná-la incluindo alterar as views
        </p>
      </>
    ),
  },
};
