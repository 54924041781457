import { Hero } from './components/Hero';
import { Screenshot } from './components/Screenshot';
import { Features } from './components/Features';

export const CreatorsPage = () => (
  <>
    <Hero
      heading="creatorsHeading"
      subHeading="creatorsLongMessage"
      explanation="creatorsSetup"
    />
    <Screenshot
      image="/app-images/home-creator-screenshot-1.png"
      title="creatorsAdd"
      description="creatorsAddDescription"
    />
    <Screenshot
      image="/app-images/home-creator-screenshot-2.png"
      title="creatorsGroup"
      description="creatorsGroupDescription"
      right
    />
    <Screenshot
      image="/app-images/home-creator-screenshot-3.png"
      title="creatorsShare"
      description="creatorsShareDescription"
    />

    <Features
      features={[
        {
          image: '/icons/icon-users.svg',
          title: 'creatorsAudience',
          description: 'creatorsAudienceDescription',
        },
        {
          image: '/icons/icon-dollar.svg',
          title: 'creatorsAds',
          description: 'creatorsAdsDescription',
        },
      ]}
    />
    <Features
      features={[
        {
          image: '/icons/icon-envelope.svg',
          title: 'creatorsEmail',
          description: 'creatorsEmailDescription',
        },
        {
          image: '/icons/icon-eye.svg',
          title: 'creatorsAccess',
          description: 'creatorsAccessDescription',
        },
      ]}
    />
  </>
);
