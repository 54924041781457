import React, { useContext, useEffect } from 'react';
import { ENGLISH, PORTUGUESE } from '../languages';
import { Context } from '../state/Store';
import { ACTIONS } from '../state/Reducer';
import BrSquare from './flags/br-square.svg';
import UsSquare from './flags/us-square.svg';

export const LanguageChange = ({
  classWidth = 'w-5',
  classHeight = 'h-5',
  className,
}) => {
  const [{ userLanguage: currentLanguage }, dispatch] = useContext(Context);
  const setLanguage = (language) => {
    dispatch({ type: ACTIONS.SET_LANGUAGE, payload: language });
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set('lang', language);
    window.history.replaceState(
      null,
      null,
      `${window.location.pathname}?${urlSearchParams.toString()}`
    );
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const lang = params.lang;
    if (lang && lang !== currentLanguage) {
      setLanguage(lang);
    }
  });
  return (
    <div className={`grid grid-cols-2 gap-4 z-50 ${className}`} role="menu">
      <button
        className={`${classHeight} ${classWidth} overflow-hidden rounded-full ${
          currentLanguage !== PORTUGUESE ? 'grayscale' : ''
        }`}
        onClick={() => setLanguage(PORTUGUESE)}
        type="button"
      >
        <img src={BrSquare} alt="br" title="Português" />
      </button>
      <button
        className={`${classHeight} ${classWidth} overflow-hidden rounded-full ${
          currentLanguage !== ENGLISH ? 'grayscale' : ''
        }`}
        onClick={() => setLanguage(ENGLISH)}
        type="button"
      >
        <img src={UsSquare} title="English" alt="us" />
      </button>
    </div>
  );
};
