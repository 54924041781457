import { useTranslate } from '../i18n/hooks/use-translate';
import { GetStarted } from './GetStarted';
import { Link, useLocation } from 'react-router-dom';
import { getHrefWithSameQueryParams } from '../utilities';

const Footer = () => {
  const { i18n } = useTranslate();
  const { pathname } = useLocation();
  return (
    <footer className="bg-neutral_700 w-full py-12">
      <div className="flex flex-col items-center justify-center">
        {pathname !== '/pricing' && (
          <>
            <h3 className="text-center text-xl md:text-2xl text-white">
              {i18n('readyToGetStarted')}
            </h3>

            <div className="mb-8">
              <GetStarted />
            </div>
          </>
        )}

        {pathname !== '/creators' && (
          <p className="text-md mb-2 mt-2 text-gray-200">
            <Link to={getHrefWithSameQueryParams('/creators')}>
              {i18n('areYouACreator')}
            </Link>
          </p>
        )}

        {pathname !== '/nerds' && (
          <p className="text-md mb-2 mt-2 text-gray-200">
            <Link to={getHrefWithSameQueryParams('/nerds')}>
              {i18n('areYouANerd')}
            </Link>
          </p>
        )}

        {pathname !== '/optimizers' && (
          <p className="text-md mb-2 mt-2 text-gray-200">
            <Link to={getHrefWithSameQueryParams('/optimizers')}>
              {i18n('areYouAnOptimizer')}
            </Link>
          </p>
        )}

        <div className="flex flex-wrap gap-3 justify-center text-gray-200 text-sm mt-8">
          <Link to={getHrefWithSameQueryParams('/pricing')}>
            {i18n('pricing')}
          </Link>
          <a href="mailto:lemeno@quave.dev" target="_blank" rel="noreferrer">
            {i18n('contact')}
          </a>
          <a
            href={getHrefWithSameQueryParams('https://twitter.com/Lemenoio')}
            target="_blank"
            rel="noreferrer"
          >
            Twitter
          </a>
          <a
            href={getHrefWithSameQueryParams(
              'https://www.youtube.com/@lemenoio'
            )}
            target="_blank"
            rel="noreferrer"
          >
            YouTube
          </a>
          <a
            href={getHrefWithSameQueryParams(
              'https://discord.com/invite/4NG2pzK2aY'
            )}
            target="_blank"
            rel="noreferrer"
          >
            Discord
          </a>
          <Link to={getHrefWithSameQueryParams('/release-notes')}>
            {i18n('releaseNotesHeader')}
          </Link>
        </div>

        <p className="text-xs text-gray-200 mt-8">{i18n('allRights')}</p>

        <p className="text-xs text-gray-200">
          {i18n('by')}
          <a
            target="_blank"
            rel="noreferrer"
            href={getHrefWithSameQueryParams('https://quave.dev')}
            className="underline"
          >
            quave
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
