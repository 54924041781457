import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ErrorBoundary } from 'react-error-boundary';
import Header from './components/Header';
import Footer from './components/Footer';
import { Store } from './i18n/state/Store';
import { TERMS } from './i18n-terms';
import { getText } from './i18n/i18n-helpers';
import { NotFound } from './NotFound';
import { AppRoutes } from './AppRoutes';

const browserHistory = createBrowserHistory();

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      {process.env.NODE_ENV === 'development' && (
        <div className="bg-white pl-10 pt-10">
          <p>DEV ONLY!</p>
          <p>Something went wrong:</p>
          <pre>{error.message}</pre>
          <button onClick={resetErrorBoundary}>Try again</button>
        </div>
      )}
      <NotFound resetErrorBoundary={resetErrorBoundary} />
    </div>
  );
}

const changePageTile = (userLanguage) => {
  document.title = getText(TERMS, 'pageTitle', { language: userLanguage });
};

function App() {
  return (
    <Store languageData={TERMS} onLanguageChange={changePageTile}>
      <BrowserRouter history={browserHistory}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <div className="bg-neutral flex flex-col items-center">
            <div className="max-w-7xl mx-4">
              <Header />
              <AppRoutes />
            </div>
            <Footer />
          </div>
        </ErrorBoundary>
      </BrowserRouter>
    </Store>
  );
}

export default App;
