import { Hero } from './components/Hero';
import { Screenshot } from './components/Screenshot';
import { Features } from './components/Features';

export const HomePage = () => (
  <>
    <Hero
      heading="keepYourSocialZero"
      subHeading="lemenoDescription"
      explanation="takeYourSocialInboxToZero"
    />
    <Screenshot
      image="/app-images/home-creator-screenshot-1.png"
      title="sources"
      description="sourcesDescription"
    />
    <Screenshot
      image="/app-images/timeline.png"
      title="read"
      description="readDescription"
      right
    />

    <Features
      features={[
        {
          image: '/icons/icon-archive.svg',
          title: 'archive',
          description: 'archiveDescription',
        },
        {
          image: '/icons/icon-user-group.svg',
          title: 'groupSources',
          description: 'groupSourcesDescription',
        },
        {
          image: '/icons/icon-bookmark.svg',
          title: 'readLater',
          description: 'readLaterDescription',
        },
      ]}
    />
  </>
);
