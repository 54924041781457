import { useTranslate } from '../i18n/hooks/use-translate';
import {getAppSignUpHrefWithQueryParams} from "../utilities";

export const GetStarted = () => {
  const { i18n } = useTranslate();
  return (
    <span className="mt-6 flex flex-col items-center space-y-4">

      <a
        className="bg-primary hover:bg-primary/80 rounded py-3 px-4 text-neutral font-semibold text-sm"
        href={getAppSignUpHrefWithQueryParams()}
      >
        {i18n('getStarted')}
      </a>
      <img src="/images/sketch-horizontal-lines.svg" alt="" />
    </span>
  );
};
