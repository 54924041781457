import { useTranslate } from './i18n/hooks/use-translate';
import {getHrefWithSameQueryParams} from "./utilities";
import {PORTUGUESE} from "./i18n/languages";

const SubscribeNow = () => {
  const { i18n, language } = useTranslate();
  return (
    <span className="mt-6 flex flex-col items-center space-y-4">
      <a
        className="bg-primary hover:bg-primary/80 rounded py-3 px-4 text-neutral font-semibold text-sm"
        href={getHrefWithSameQueryParams(language === PORTUGUESE ? "https://buy.stripe.com/fZe4jx3Fk1Mj8G49AD" : "https://buy.stripe.com/3csg2f2Bg3UrbSg002")}
        target="_blank"
        rel="noreferrer"
      >
        {i18n('subscribeNow')}
      </a>

      <img src="/images/sketch-horizontal-lines.svg" alt="" />
    </span>
  );
};

export const PricingPage = () => {
  const { i18n } = useTranslate();

  return (
    <div className="flex flex-col items-center">
      <div>
        <h2 className="text-center text-white text-4xl lg:text-5xl mb-4">
          {i18n('pricingHeader')}
        </h2>
        <p className="text-center md:text-xl text-neutral_300">
          {i18n('pricingSubheader')}
        </p>
      </div>
      <div className="w-3/4 mt-10 space-y-4">
        {i18n('pricingText')}
      </div>
      <div className="w-3/4 mt-10 space-y-4">
        {i18n('paidBenefitsText')}
      </div>
      <div className="w-3/4 mt-10 space-y-4 mb-20">
        {i18n('paymentLinkText')}
      </div>
      <div className="mb-20">
        <SubscribeNow />
      </div>
    </div>
  );
};
